import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Container } from "reactstrap";
import PaymentSideBar from "../../components/SideBar/CheckoutPayment";
import AppointmentDetails from "../../components/AppointmentDetails";
import CheckoutDetails from "../../components/CheckoutDetails";
import Svg from "../../components/Svg/index.js";
import { useTranslation } from "react-i18next";
import authProvider from "../../helpers/authProvider";
import { toast } from "react-toastify";
import { DASHBOARD_URL } from "../../urls";

const Bookings = (props) => {
  const { t } = useTranslation("common");
  const [people, setPeople] = useState([]);
  const [address, setAddress] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [notes, setNotes] = useState("");
  const [costData, setCostData] = useState(null);
  const [appointmentType, setAppointmentType] = useState("scheduled");
  const [paymentSideBarOpen, setpPaymentSidebBarOpen] = useState(false);
  const [checkoutEnabled, setCheckoutEnabled] = useState(false);
  const [notesWords, setNotesWords] = useState(0);
  const [maxNotesWords] = useState(100);
  const [paymentMode, setPaymentMode] = useState("card");

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    initView();
  }, [props.location.state]);

  useEffect(() => {
    window.addEventListener("profileTypeChanged", onProfileTypeChanged, true);

    return function cleanup() {
      window.removeEventListener(
        "profileTypeChanged",
        onProfileTypeChanged,
        true
      );
    };
  }, []);

  const onProfileTypeChanged = () => {
    initView(true);
    setDiscountCode("");
    setCostData(null);
    setpPaymentSidebBarOpen(false);
    setCheckoutEnabled(false);
    setNotes("");
    setScheduledDate(null);
    setNotesWords(0);
  };

  useEffect(() => {
    if (
      people &&
      address &&
      (scheduledDate || appointmentType === "asap") &&
      costData &&
      notesWords <= maxNotesWords
    ) {
      setCheckoutEnabled(true);
    } else {
      setCheckoutEnabled(false);
    }
  }, [
    people,
    address,
    scheduledDate,
    costData,
    notesWords,
    maxNotesWords,
    appointmentType,
  ]);

  useEffect(() => {
    if (costData && costData.subtotal == 0) {
      setPaymentMode("nocharge");
    } else {
      setPaymentMode("card");
    }
  }, [costData]);

  const initView = (ignoreServices = false) => {
    let peopleArr = [];
    let preSelectedService = props.location.state
      ? props.location.state.service
      : null;

    if (props.location.state) {
      if (
        props.location.state.people &&
        props.location.state.people.length > 0
      ) {
        props.location.state.people.forEach((person) => {
          if (preSelectedService) {
            peopleArr.push(
              Object.assign(
                {
                  services: ignoreServices ? [] : [preSelectedService],
                },
                person
              )
            );
          } else {
            peopleArr.push(
              Object.assign(
                {
                  services: ignoreServices ? [] : person.services,
                },
                person
              )
            );
          }
        });
      }

      if (props.location.state.address) {
        setAddress(props.location.state.address);
      }
    } else {
      let newPerson = { ...authProvider.getUser() };
      newPerson.services = [];
      if (preSelectedService && !ignoreServices) {
        newPerson.services = ignoreServices ? [] : [preSelectedService];
      }

      peopleArr.push(newPerson);
    }

    setPeople(peopleArr);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("newBooking.title")}</title>
      </Helmet>

      <main
        style={{ minHeight: "90vh" }}
        className="d-flex flex-column appointment-page"
      >
        <Container>
          <section className="flex-column d-flex gap-16">
            <span
              className="mr-auto cursor-pointer"
              onClick={() => props.history.goBack()}
              style={{}}
            >
              <Svg
                name="arrow-left"
                className="page-back-arrow theme-dark-color"
              />
            </span>

            <div
              className="flex-row d-flex page-header-container"
              style={{ alignItems: "flex-start" }}
            >
              <h2 className="page-header mr-auto">
                {t("newBooking.title")}
                <span
                  className="text-small text-normal sub-text regular"
                  style={{ marginLeft: "10px" }}
                >
                  {authProvider.getCurrentProfileType() === "personal"
                    ? t("corporate.personalAccount")
                    : t("corporate.corporateAccount")}
                </span>
              </h2>
              <Button
                className="ml-auto btn-accent"
                disabled={!checkoutEnabled}
                onClick={() => {
                  setpPaymentSidebBarOpen(true);
                }}
              >
                {t("newBooking.checkoutBtn")}
              </Button>
            </div>
          </section>

          <section className="d-flex booking-section" style={{ gap: "24px" }}>
            <AppointmentDetails
              address={address}
              scheduledDate={scheduledDate}
              scheduleChanged={(date, type) => {
                setScheduledDate(date);
                setAppointmentType(type);
              }}
              updateLocation={(location) => {
                setAddress(location);
              }}
              notes={notes}
              editMode={true}
              people={people}
              discountCode={discountCode}
              updatePeopleArray={(list) => setPeople(list)}
              updateDiscountCode={(value) => setDiscountCode(value)}
              updateNotes={(value, words) => {
                setNotes(value || "");
                setNotesWords(words);
              }}
              maxNotesWords={maxNotesWords}
              {...props}
            />

            <CheckoutDetails
              discountCode={discountCode}
              address={address}
              scheduledDate={scheduledDate}
              appointmentType={appointmentType}
              people={people}
              costUpdated={(cost) => {
                setCostData(cost);
              }}
              {...props}
            />
          </section>
        </Container>
      </main>

      <PaymentSideBar
        sidebarGroup="app-payment"
        // paymentMode="card"
        paymentMode={paymentMode}
        onSuccess={(appId) => {
          toast.dark(
            t(
              `newBooking.${
                appointmentType === "asap"
                  ? "asapRequestReceived"
                  : "bookedMessage"
              }`
            ),
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );

          props.history.push(DASHBOARD_URL);
        }}
        isOpen={paymentSideBarOpen}
        toggle={() => setpPaymentSidebBarOpen(false)}
        address={address}
        scheduledDate={scheduledDate}
        appointmentType={appointmentType}
        notes={notes}
        people={people}
        cost={costData}
        discountCode={discountCode}
        {...props}
      />
    </React.Fragment>
  );
};
export default Bookings;
